.understanding {
    padding: 70px 100px;
}

.understanding>h5 {
    font-family: LatoBold;
    font-size: 32px;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
    margin-bottom: 20px;
    text-align: center;
}

.understanding>p {
    font-family: SofiaProRegular;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
    width: 709px;
    margin: auto;
    text-align: center;
}

.needs {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin-top: 100px;
}

.needs>div {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 60px;
}

.needs div>div>h5:first-of-type {
    color: #FC6F92;
    font-size: 30px;
    font-family: LatoBold;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.needs div>div>h5:last-of-type {
    color: #510115;
    font-size: 24px;
    font-family: LatoBold;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.needs p {
    font-family: SofiaProRegular;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
    padding-right: 50px;
}

.feeding-img {
    position: relative;
    height: 360px;
}

.feeding-img1 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

.feeding-img2 {
    position: absolute;
    top: 75px;
    left: 130px;
    z-index: 0;
}

.feeding-img3 {
    position: absolute;
    left: 30px;
    bottom: 0;
    z-index: 10;
}

.sleeping-img {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.sleeping-img1,
.sleeping-img2 {
    position: relative;
    width: 165px;
    height: 268px;
}

.sleeping-img1 *,
.sleeping-img2 * {
    position: relative;
}

.sleeping-img1::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 263px;
    bottom: -10px;
    left: -10px;
    background-color: #FC6F92;
    border-radius: 10px;

}

.sleeping-img2::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 263px;
    top: -10px;
    right: -10px;
    background-color: #FFD700;
    border-radius: 10px;

}

.sleeping-img2 {
    margin-top: 30px;
}

.dev-img1 {
    position: relative;
    width: 266px;
    height: 300px;
}

.dev-img1 * {
    position: relative;
    z-index: 100;
}

.dev-img1::before {
    position: absolute;
    content: '';
    height: 50px;
    width: 50px;
    border-radius: 50%;
    bottom: -18px;
    left: -15px;
    background-color: #FC6F92;
    z-index: 0;
}

.dev-img1::after {
    position: absolute;
    content: '';
    height: 50px;
    width: 50px;
    border-bottom-left-radius: 50px;
    top: -18px;
    right: -15px;
    background-color: #F6E5FA;
    z-index: 0;
}

@media(max-width: 780px) {
    .understanding {
        padding: 3vw 3.5vw 10vw;
    }

    .understanding>h5 {
        font-size: 3vw;
    }

    .understanding>p {
        font-size: 2.5vw;
        line-height: 120%;
        width: 90vw;
    }

    .needs {
        gap: 5vw;
        margin-top: 15vw;
    }

    .needs>div {
        display: flex;
        gap: 0;
        justify-content: space-between;
        align-items: center;
    }

    .needs div>div>h5:first-of-type {
        font-size: 3.3vw;
    }

    .needs div>div>h5:last-of-type {
        font-size: 2.8vw;
    }

    .needs p {
        font-size: 2.5vw;
        line-height: 120%;
        padding-right: 0;
    }

    .feeding-img {
        height: 30vw;
        width: 40vw;
    }

    .feeding-img2 {
        width: 25.5vw;
        top: 6vw;
        left: 11vw;
    }

    .feeding-img1 {
        width: 18.5vw;
    }

    .feeding-img3 {
        width: 14.3vw;
        left: 3vw;
        bottom: -1vw;
    }

    .sleeping-img {
        gap: 1vw;
    }

    .sleeping-img1,
    .sleeping-img2,
    .sleeping-img1 img,
    .sleeping-img2 img,
    .sleeping-img1::before,
    .sleeping-img2::before {
        width: 16.7vw;
        height: 27vw;
    }

    .sleeping-img1::before {
        bottom: -1vw;
        left: -1vw;
    }

    .sleeping-img2::before {
        right: -1vw;
        top: -1vw;
    }

    .dev-img {
        padding-left: 10vw;
    }

    .dev-img1,
    .dev-img1 img {
        width: 25vw;
        height: 28.2vw;
    }

    .needs-sleeping,
    .needs-feeding,
    .needs-dev {
        width: 45vw;
    }

}