.symptoms {
    padding: 4.45vw 5.93vw 2.97vw 8.89vw;
    margin-top: 5vw;
}

.symptoms>h5 {
    font-family: LatoBold;
    font-size: 2.22vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
}

.p-symptoms {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 1.5vw 4.45vw;
    margin-top: 3.7vw;
}

.p-symptoms div {
    border: 1px #745464 solid;
    border-radius: 10px;
    padding: 2.22vw 0.74vw 0.74vw 1.5vw;
}

.p-symptoms div:hover {
    background-color: #FC6F92;
    border: 1px #FC6F92 solid;
}

.p-symptoms div:hover * {
    color: #FBFBFB;
}


.p-symptoms div h5 {
    font-family: LatoBold;
    font-size: 1.33vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
}

.p-symptoms div p {
    font-family: SofiaProRegular;
    font-size: 1.19vw;
    line-height: 120%;
    letter-spacing: 0;
    color: #745464;
}

@media(max-width: 780px) {
    .symptoms {
        padding: 15vw 5vw 10vw 3.5vw;
    }

    .symptoms>h5 {
        font-size: 3vw;
    }

    .p-symptoms {
        grid-template-columns: auto auto;
        gap: 2.8vw 3.1vw;
        margin-top: 4vw;
    }

    .p-symptoms div {
        padding: 2.9vw 2.9vw 3.1vw 1.5vw;
        border-radius: 5px;
    }

    .p-symptoms div h5 {
        font-size: 2.5vw;
    }

    .p-symptoms div p {
        font-size: 2.2vw;
    }
}