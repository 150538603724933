.support {
    padding: 8.5vw 7.41vw;
}

.support>h5 {
    font-family: LatoBold;
    font-size: 2.4vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
    margin-bottom: 1.5vw;
    text-align: center;
}

.p-support {
    display: flex;
    flex-direction: row;
    gap: 2.97vw;

}

.p-support img {
    border-radius: 0.75vw;
    width: 48.18vw;
    height: fit-content;
}

.support-bd {
    background-color: #FFF1F4;
    padding: 7.41vw 0.75vw 0 3.7vw;
    border-radius: 0.75vw;
}

.support-bd h5 {
    font-family: LatoBold;
    font-size: 2.97vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
    margin-bottom: 1.5vw;
}

.support-bd p {
    font-family: SofiaProRegular;
    font-size: 1.33vw;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
}

@media(max-width: 780px) {
    .support {
        padding: 7vw 3.5vw 10vw;
    }

    .support>h5 {
        font-size: 3vw;
        margin-bottom: 3.7vw;
        line-height: 120%;
    }

    .p-support {
        gap: 3vw;
        height: 43vw;
    }

    .p-support img {
        width: 42vw;
        height: 100%;
        object-fit: cover;
    }

    .support-bd {
        padding: 5vw 2vw 0 5vw;
    }

    .support-bd h5 {
        font-size: 2.7vw;
        line-height: 120%;
        margin-bottom: 2vw;
    }

    .support-bd p {
        font-size: 2.3vw;
        line-height: 120%;
    }
}