.find {
    padding: 1.5vw 7.4vw 1.5vw 9.34vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2.22vw;
}

.find-gyn1 p {
    font-family: SofiaProRegular;
    font-size: 1.33vw;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
    margin-top: 2.97vw;
}

.no-colour-btn {
    border: 1px #FFD700 solid;
    color: #510115;
    font-family: LatoBold;
    font-size: 1.5vw;
    line-height: 140%;
    letter-spacing: 0;
    padding: 1.11vw 2.22vw;
    border-radius: 0.74vw;
    background-color: #fff;
}

.no-colour-btn:hover {
    background-color: #FFD700;
}

.find-gyn2 {
    width: 40.77vw;
}

.find-gyn2 h5 {
    font-family: LatoBold;
    font-size: 2.97vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
    margin-bottom: 1.5vw;
}

.find-gyn2 img {
    width: 38.55vw;
}

@media(max-width: 780px) {
    .find {
        padding: 2vw 5vw 10vw 3.5vw;
        flex-direction: column;
        gap: 3vw;
    }

    .find-gyn1,
    .find-gyn2 {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4vw;
    }

    .find img {
        width: 47vw;
    }

    .find-gyn1 h5 {
        font-size: 3.2vw;
        font-family: LatoBold;
        color: #510115;
        line-height: 140%;
        letter-spacing: 0;
    }

    .find-gyn2 {
        width: unset;
    }

    .find-gyn2 p {
        font-size: 2.2vw;
        font-family: SofiaProRegular;
        line-height: 140%;
        letter-spacing: 0;
        color: #745464;
    }

    .no-colour-btn {
        font-size: 2.6vw;
        background-color: #FFD700;
        border: none;
        padding: 1.6vw 3.5vw;
        border-radius: 4px;
    }

    .no-colour-btn:hover {
        border: 1px #FFD700 solid;
        color: #510115;
        background-color: #fff;
    }
}