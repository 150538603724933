.prepare {
    padding: 5vw 7.41vw;
    display: flex;
    flex-direction: row;
    gap: 1.5vw;
}

.p-prepare>h5 {
    font-family: LatoBold;
    font-size: 2.22vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
}

.p-prepare>p {
    font-family: SofiaProRegular;
    font-size: 1.33vw;
    color: #745464;
    line-height: 140%;
    letter-spacing: 0;
    padding-right: 2.22vw;
}

.prepare-steps {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 2.22vw;
    row-gap: 0.75vw;
}

.prepare-steps div:last-of-type {
    grid-column: 1 / span 2;
    width: 20.44vw;
    margin: -2.97vw auto 0 auto;

}

.prepare-steps div:last-of-type>h5:first-of-type {
    text-align: center;
}

.prepare-steps div>h5:first-of-type {
    color: #FC6F92;
    font-family: LatoBold;
    font-size: 2.22vw;
    font-style: normal;
    line-height: 140%;
}

.prepare-steps div>h5:last-of-type {
    color: #510115;
    font-family: LatoBold;
    font-size: 1.78vw;
    font-style: normal;
    line-height: 140%;
}

.prepare-steps div>p {
    font-family: SofiaProRegular;
    font-size: 1.33vw;
    color: #745464;
    line-height: 140%;
    letter-spacing: 0;
}

.prepare-img {
    display: flex;
    flex-direction: row;
    gap: 1.5vw;
    margin-top: 7.41vw;
}

.prepare-img1,
.prepare-img2 {
    display: flex;
    flex-direction: column;
    gap: 1.5vw;
}

.prepare-img1 {
    justify-content: flex-start;
}

.prepare-img2 {
    margin-top: 5.19vw;
}

.prepare-img img {
    border-radius: 0.75vw;
}

@media(max-width: 780px) {
    .prepare {
        padding: 7vw 5vw 10vw 3.5vw;
        display: block;
    }

    .prepare>h5 {
        font-family: LatoBold;
        font-size: 3vw;
        color: #510115;
        line-height: 120%;
        letter-spacing: 0;
    }

    .prepare>p {
        font-family: SofiaProRegular;
        font-size: 2.5vw;
        color: #745464;
        line-height: 120%;
        letter-spacing: 0;
        padding-right: 3vw;
    }

    .prep-steps {
        display: flex;
        flex-direction: column;
        gap: 6vw;
    }

    .prep-steps>div {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .prep-steps>div>div {
        max-width: 40vw;
    }

    .prep-steps>div>div>h5:first-of-type,
    .prepare>div:last-of-type>h5:first-of-type {
        color: #FC6F92;
        font-family: LatoBold;
        font-size: 3.3vw;
        font-style: normal;
        line-height: 120%;
    }

    .prep-steps>div>div>h5:last-of-type,
    .prepare>div:last-of-type>h5:last-of-type {
        color: #510115;
        font-family: LatoBold;
        font-size: 2.8vw;
        font-style: normal;
        line-height: 120%;
    }

    .prep-steps>div>div>p,
    .prepare>div:last-of-type>p {
        font-family: SofiaProRegular;
    font-size: 2.5vw;
    color: #745464;
    line-height: 120%;
    letter-spacing: 0;
    }

    .prep-steps>div>img {
        width: 35vw;
        border-radius: 10px;
        height: 38vw;
        object-fit: cover;
    }

}