.fitness {
    padding: 2.97vw 4.45vw 2.22vw;
    margin: 5vw 0;
}

.fitness-hd {
    text-align: center;
    padding: 0.74vw 6.67vw;
}

.fitness-hd>h5 {
    font-family: LatoBold;
    font-size: 2.22vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
}

.fitness-hd>p {
    font-family: SofiaProRegular;
    font-size: 1.19vw;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
}

.yoga>h5 {
    padding: 2.97vw 0;
    text-align: center;
    font-family: LatoSemibold;
    font-size: 1.78vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
}

.poses {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}


.poses div {
    position: relative;
    width: 250px;
}

.poster {
    width: 100%;
}

.play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.play-btn:hover {
    opacity: 0.5;
}


.avoid {
    padding-top: 60px;
}


.avoid h5 {
    font-family: LatoBold;
    font-size: 2.22vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
    text-align: center;
}

.activity {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 1.5vw;
    padding-top: 2.22vw; 
}

.activity div {
    border: 1px #745464 solid ;
    border-radius: 10px;
    padding: 2.22vw 1.5vw 1.5vw 2.22vw;
    color: #745464;
    font-family: LatoSemibold;
    font-size: 1.78vw;
    line-height: 140%;
    letter-spacing: 0;
    width: 25.94vw;
    height: 8.89vw;
    text-align: center;
}

.activity div:hover {
    background-color: #FC6F92;
    color: #F5F5F5;
    border: 1px #FC6F92 solid;
}

@media(max-width: 780px) {
    .fitness {
        padding: 15vw 5vw 10vw 3.5vw;
    }

    .fitness-hd {
        padding: 3.1vw 4vw 3.5vw 4vw;
    }

    .fitness-hd>h5 {
        font-size: 3vw;
    }

    .fitness-hd>p {
        font-size: 2.5vw;
    }

    .play-btn {
        width: 3vw;
    }
    .avoid {
        display: none;
    }
}