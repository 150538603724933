.safety-container {
    background-color: #FFF1F4;
}

.safety {
    padding: 70px 100px;
    display: flex;
    gap: 100px;
}

.safety-dt>h5 {
    font-family: LatoBold;
    font-size: 30px;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
    margin-bottom: 20px;
}

.safety-dt li {
    color: #745F64;
    font-size: 18px;
    font-family: SofiaProRegular;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.safety-dt li::marker {
    color: #FC6F92;
}

.safety-img {
    position: relative;
    width: 252px;
}

.safety-img * {
    position: relative;
}

.safety-img::before {
    position: absolute;
    content: '';
    width: 200px;
    height: 200px;
    border-radius: 10px;
    top: -10px;
    right: -10px;
    background-color: #F2527C;
}

@media(max-width: 780px) {
    .safety {
        padding: 8vw 3.5vw;
        gap: 5vw;
        align-items: center;
    }

    .safety-dt>h5 {
        font-size: 3.2vw;
    }

    .safety-dt li {
        font-size: 2.3vw;
        line-height: 120%;
    }

    .safety-img,
    .safety-img img {
        width: 24.3vw;
    }

    .safety-img::before {
        width: 18.3vw;
        height: 19.3vw;
        top: -1.5vw;
        right: -1.5vw;
    }
}