.corner {
    padding: 60px 100px;
}

.corner>h5 {
    color: #510115;
    font-size: 44px;
    font-family: LatoBold;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-align: center;
}

.corner>p {
    font-family: SofiaProRegular;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
    text-align: center;
    padding: 0 50px;
}

.corner-info {
    display: grid;
    grid-template-columns: auto auto;
    gap: 40px;
    margin-top: 80px;
}


.info-dt h5 {
    color: #510115;
    font-size: 30px;
    font-family: LatoBold;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin: 30px 0;
}

.info-dt p {
    font-family: SofiaProRegular;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
}

.info1-img-container {
    display: flex;
    background-color: #FFF1F4;

}

.info1-img-container>div,
.info4-img-container>div:first-of-type {
    padding: 30px 20px;
    display: flex;
    align-items: center;
}

.info1-img-container>div>h5,
.info4-img-container>div>h5 {
    color: #510115;
    font-size: 26px;
    font-family: LatoBold;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.info2-img-container {
    position: relative;
    width: 580px;
}

.info2-img-container>div {
    position: absolute;
    width: 460px;
    height: 174px;
    left: 60px;
    top: 70px;
    background-color: rgba(239, 205, 203, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.info2-img-container h5 {
    color: #510115;
    font-size: 26px;
    font-family: LatoBold;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    padding: 0 50px;
    text-align: center;
}

.info3-img-container {
    position: relative;
    width: 580px;
}

.info3-img-container::before {
    position: absolute;
    content: '';
    width: 50px;
    height: 50px;
    background-color: #FDAAB9;
    left: 20px;
    bottom: 70px;
    transform: rotateZ(45deg);
}

.info3-img-container::after {
    position: absolute;
    content: '';
    width: 25px;
    height: 25px;
    background-color: #FDAAB9;
    left: 100px;
    bottom: 80px;
    transform: rotateZ(45deg);
}

.info4-img-container {
    display: flex;
    background-color: #F6E5FA;
}

.info4-img-container>div:last-of-type {
    position: relative;
    width: 224px;
}

.info4-img-container>div>img:last-of-type {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media(max-width: 780px) {
    .corner {
        padding: 3vw 3.5vw;
    }

    .corner>h5 {
        font-size: 3vw;
    }

    .corner>p {
        font-size: 2.5vw;
        line-height: 120%;
    }

    .corner-info {
        display: flex;
        flex-direction: column;
        gap: 5vw;
    }

    .info-dt h5 {
        font-size: 2.8vw;
        margin: 2.5vw 0
    }

    .info-dt p {
        font-size: 2.5vw;
    }

    .info1-img-container>div,
    .info4-img-container>div:first-of-type {
        padding: 3.2vw 10vw 3.2vw 3.8vw;
    }

    .info1-img-container>div>h5,
    .info4-img-container>div>h5 {
        font-size: 2.8vw;
    }

    .info1-img-container img {
        width: 30.5vw;
    }

    .info2-img-container,
    .info3-img-container {
        width: 85vw;
    }

    .info2-img-container img,
    .info3-img-container img {
        width: 85vw;
    }

    .info2-img-container>div {
        width: 57vw;
        height: 22vw;
        top: 12vw;
        left: 14vw;
    }

    .info2-img-container h5 {
        font-size: 2.8vw;
    }

    .info3-img-container::before {
        width: 6.8vw;
        height: 6.8vw;
        left: 2.5vw;
        bottom: 12vw;
    }

    .info3-img-container::after {
        width: 4.5vw;
        height: 4.5vw;
        left: 14vw;
        bottom: 12.3vw;
    }

    .info4-img-container img:first-of-type {
        width: 32.5vw;
    }

    .info4-img-container img:last-of-type {
        width: 26.7vw;
    }

    .corner>.more {
        padding-top: 5vw;
    }
}