.popular {
    padding: 5vw 8.9vw;
}

.popular>h5 {
    font-family: LatoBold;
    font-size: 25px;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
    margin-bottom: 1.5vw;
}

.post-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 10px;
}

.post,
.post>img {
    width: 235px;
}

.post a {
    font-family: LatoSemibold;
    font-size: 24px;
    text-decoration: underline;
    color: #745464;
    line-height: 140%;
    letter-spacing: 0;
}

@media(max-width: 780px) {
    .popular {
        padding: 15vw 5.1vw;
    }

    .popular>h5 {
        font-size: 3vw;
    }

    .post-container {
        grid-template-columns: auto auto;
        column-gap: 10px;
        row-gap: 20px;
    }

    .post,
    .post>img {
        width: 45vw;
    }

    .post a {
        font-size: 2.8vw;
    }
}