.manage {
    padding: 8.5vw 7.41vw;
    display: flex;
    align-items: center;
    gap: 2.97vw;
}

.mgt-ways {
    background-color: #FFF1F4;
    border-radius: 0.75vw;
    padding: 7.41vw 3.7vw 2.22vw 1.5vw;
}

.pain-mgt>h5 {
    color: #510115;
    font-size: 2.22vw;
    font-family: LatoBold;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0;
}

.pain-mgt p {
    color: #745464;
    font-size: 1.33vw;
    font-family: SofiaProRegular;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0;
}

.mgt-ways h5{
    color: #510115;
    font-size: 1.78vw;
    font-family: LatoBold;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0;
}

.mgt-ways li {
    color: #510115;
    margin-bottom: 2.22vw;
}

.mgt-img {
    margin-top: 5.93vw;
    display: flex;
    gap: 1.5vw;
}

.mgt-img1 {
    display: flex;
    flex-direction: column;
    gap: 2.97vw;
}

.mgt-img1 img:first-of-type,
.mgt-img2 img:first-of-type {
    width: 15.94vw;
    margin-left: 4.45vw;
}

.mgt-img2 img:last-of-type {
    width: 15.94vw;
}


.mgt-img2 {
    margin-top: 3.7vw;
    display: flex;
    flex-direction: column;
    gap: 6.67vw;
}

@media(max-width: 780px) {
    .manage {
        padding: 0;
    }

    .pain-mgt {
        margin: 5vw 0;
    }
    
    .pain-mgt>h5,
    .pain-mgt>p {
        padding: 1vw 3.5vw 0;
    }

    .mgt-ways {
        padding: 10vw 3.5vw;
    }

    .pain-mgt>h5 {
        font-size: 3vw;
        margin-top: 8vw;
        margin-bottom: 0;
    }

    .pain-mgt p {
        font-size: 2.5vw;
        margin-bottom: 6vw;
    }

    .mgt-ways h5 {
        font-size: 2.8vw;
    }

    .mgt-ways li>div {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .mgt-ways li>div>div {
        width: 55vw;
    }

    .mgt-ways li:first-of-type img {
        width: 28vw;
    }

    .mgt-ways li:nth-child(2) img {
        width: 17vw;
    }
    .mgt-ways li:nth-child(3) img {
        width: 25vw;
    }
    .mgt-ways li:nth-child(4) img {
        width: 22vw;
    }

    .mgt-ways li::marker {
        color: #FC6F92;
        transform: translateY(5vw);
    }

    
}