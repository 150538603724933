.tracker {
    padding: 5.93vw;
    display: flex;
    flex-direction: row;
    gap: 1.5vw;
    justify-content: center;
    align-items: center;
}

.tracker>img {
    width: 37vw;
    height: 30vw;
    object-fit: cover;
}

.p-tracker {
    background-color: rgba(255, 241, 244, 0.6);
    padding: 4vw 1.5vw 3.6vw 2.22vw;
    height: 30vw;
}

.p-tracker h5 {
    font-family: LatoRegular;
    font-size: 1.78vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
    width: 34.23vw;
}

.p-tracker p {
    font-family: SofiaProRegular;
    font-size: 1.19vw;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
    padding-top: 1.85vw;
    padding-bottom: 0.37vw;
}

@media(max-width: 780px) {
    .tracker {
        padding: 15vw 3vw 10vw 3.5vw;
        gap: 0;
    }

    .tracker>img {
        width: 40vw !important;
        height: 48.7vw;
        object-fit: cover;
    }

    .p-tracker {
        padding: 3.2vw 2.1vw 2.9vw 2.4vw;
        height: 48.7vw;
    }

    .p-tracker h5 {
        font-size: 2.8vw;
    }

    .p-tracker p {
        font-size: 2.5vw;
        padding-top: 0;
    }

    .p-tracker>.big-btn.yellow-btn {
        padding: 2vw 3.3vw ;
        font-size: 2.5vw;
        border-radius: 3px;
    }
}