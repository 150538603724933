html {
  scroll-behavior: smooth;
}

.yellow-btn {
  background-color: #FFD700;
  padding: 1.11vw 3.7vw;
  border: none;
  border-radius: 0.74vw;
  color: #510115;
}
