.signs {
    background-color: rgba(246, 229, 250, 0.5);
    padding: 4.45vw 5.93vw 2.97vw 8.89vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 13.34vw;
    margin-top: 7vw;
}

.p-signs>h5 {
    font-family: LatoBold;
    font-size: 2.22vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
}

.p-signs>p:first-of-type {
    font-family: SofiaProRegular;
    font-size: 1.19vw;
    color: #510115;
    line-height: 120%;
    letter-spacing: 0;
}

.p-signs P:last-of-type,
.p-signs li{
    font-family: SofiaProRegular;
    font-size: 1.19vw;
    line-height: 120%;
    letter-spacing: 0;
    color: #745464;
}
.p-signs ul {
    margin-left: 0;
    padding-left: 0;
}
.p-signs li {
    list-style-position: inside;
}

.p-signs li::marker {
    color: #FB9CD0;
}

.signs-img {
    position: relative;
    width: 33.36vw;
}

.signs-img::before {
    position: absolute;
    content: "";
    width: 17vw;
    height: 19.27vw;
    border-radius: 10px;
    background-color: #FB9CD0;
    right: -1.5vw;
    top: -1.5vw;
}

.signs-img * {
    position: relative;
    z-index: 100;
}

@media(max-width: 780px) {
    .signs {
        padding: 5vw 5vw 5vw 3.5vw;
        gap: 2.6vw;
        margin-top: 12vw;
    }

    .p-signs>h5 {
        font-size: 3vw;
    }

    .p-signs>p:last-of-type,
    .p-signs>ul>li {
        font-size: 2.2vw ;
        margin-bottom: 0;
    }

    .signs-img {
        width: 40vw;
    }

    .signs-img::before {
        width: 25vw;
        height: 25vw;
        top: -2vw;
        right: -2vw;
    }

    .signs-img img {
        width: 40vw;
    }
}
