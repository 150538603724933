.t-guide {
    background-color: #FFF1F4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 14.83vw;
    margin-top: 8vw;
    padding: 6vw 5.93vw 6vw 8.89vw;
}

.t-guide-c1>h5 {
    font-family: LatoBold;
    font-size: 2.22vw;
    line-height: 140%;
    letter-spacing: 0px;
    color: #510115;
}

.t-guide-c1>p {
    font-family: SofiaProRegular;
    font-size: 1.19vw;
    line-height: 140%;
    letter-spacing: 0px;
    color: #745464;
}

.img-container {
    position: relative;
    width: 25.3vw;
}

.img-container>img {
    width: 25.3vw;
}


.img-container::before {
    position: absolute;
    content: '';
    right: -1.5vw;
    top: -1.5vw;
    border-radius: 5px;
    height: 26.24vw;
    width: 16.3vw;
    background-color: rgba(252, 111, 146, 0.4);
    z-index: 0 !important;
    
}

.img-container * {
    z-index: 100;
    position: relative;
}

@media (max-width: 780px) {
    .t-guide {
        flex-direction: column;
        gap: 12vw;
        background-color: unset;
        padding: 0;
        margin-top: 16vw;
    }

    .t-guide-c1 {
        background-color: #FFF1F4;
        justify-content: center;
        align-items: center;
        padding: 3.2vw 3.2vw 3.2vw 3.5vw;
    }

    .t-guide-c1>h5 {
        font-size: 3vw;
    }

    .t-guide-c1>p {
        font-size: 2.5vw;
    }

    .img-container {
        width: 88vw;
    }

    .img-container::before {
        top: -3.5vw;
        right: -3.5vw;
        height: 88vw;
        width: 50.7vw;
    }

    .img-container>img {
        width: 100%;
    }
}