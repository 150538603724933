.postpartum {
    padding: 6vw 7.41vw;
}

.postpartum-container {
    display: flex;
    align-items: flex-end;
    gap: 1.5vw;
}

.postpartum>div>div>h5 {
    color: #510115;
    font-size: 2.22vw;
    font-family: LatoBold;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0;
}

.postpartum p {
    color: #745464;
    font-size: 1.33vw;
    font-family: SofiaProRegular;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0;
}

.postpartum-img {
    position: relative;
    width: 35.58vw;
    margin: 5.19vw 0 0 2.22vw;
}

.postpartum-img::before {
    position: absolute;
    content: '';
    right: -2.22vw;
    top: -2.22vw;
    border-radius: 0.75vw;
    height: 35.58vw;
    width: 40.03vw;
    background-color: #FFF1F4;
    z-index: 0 !important;
}

.postpartum-img * {
    position: relative;
}

.postpartum-img>img {
    width: 35.58vw;
}

.recovery h5 {
    color: #FC6F92;
    font-size: 2.22vw;
    font-family: LatoBold;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0;
    position: absolute;
    top: -0.75vw;
    left: -1.5vw;
    background-color: #fff;
}

.recovery div {
    padding-left: 2.22vw;
    height: 8.89vw;
    position: relative;
}

.recovery div:not(:last-of-type) {
    border-left: 1px #745464 solid;
}


@media(max-width: 780px) {
    .postpartum {
        padding: 7vw 3.5vw 10vw;
    }

    .postpartum-container {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 15vw;
    }

    .postpartum>h5 {
        color: #510115;
        font-size: 3vw;
        font-family: LatoBold;
        font-style: normal;
        line-height: 120%;
        letter-spacing: 0;
    }

    .postpartum p {
        font-size: 2.5vw;
    }

    .postpartum-img {
        width: 73vw;
        height: 54.5vw;
        margin: 7vw 3vw;
    }

    .postpartum-img img {
        width: 73vw;
        height: auto;
    }

    .postpartum-img::before {
        width: 77vw;
        height: 45.5vw;
        right: -2vw;
        top: -2vw;
    }

    .recovery {
        padding: 0 10vw;
    }

    .recovery h5 {
        font-size: 3.3vw;
        top: -2vw;
        left: -2.2vw;
    }
    
    .recovery div {
        height: 17.4vw;
    }
}