.testimony {
    margin: 10vw 0 5vw;
}

.testimony>h5 {
    font-family: LatoBold;
    font-size: 2.22vw;
    color: #510115;
    text-align: center;
    line-height: 140%;
    letter-spacing: 0;
    margin: 1.5vw 0;
}

.testimony .carousel-inner::before {
    content: '';
    background-color: #FB9CD0;
    border-radius: 10px;
    width: 70vw;
    left: 15vw;
    height: 44.48vw;
    margin: 2.22vw auto;
    position: absolute;
}

.tes-container {
    border-radius: 10px;
    width: 70vw;
    height: 44.48vw;
    margin: 2.22vw auto;
    position: relative;
}



.tes1,
.tes2 {
    position: absolute;
    width: 38vw;
    height: 37vw;
    top: 3.7vw;
    background-color: #FFF1F4;
    border-radius: 10px;
    padding: 3.7vw 3.7vw 0 1.85vw;
    display: flex;
    flex-direction: column;

}

.tes1 {
    left: -3.7vw;
}

.tes2 {
    right: -3.7vw;
}

.tes-child1>h5 {
    font-family: LatoBold;
    font-size: 1.8vw;
    color: #510115;
}

.tes-child1>p {
    font-family: SofiaProRegular;
    font-size: 1.11vw;
    color: #745464;
    line-height: 140%;
    letter-spacing: 0;
}

.tes-child2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    right: 2.74vw;
}

.tes-child2>img {
    width: 18vw;
}

.tes-dt {
    text-align: left;
    align-self: center;
    width: 14.82vw;
}

.tes-dt h5 {
    font-family: LatoBold;
    font-size: 1.5vw;
    color: #510115;
}

@media(max-width: 780px) {
    .testimony {
        margin: 15vw 0 5vw;
    }
    .testimony h5 {
        font-size: 3vw;
    }

    .testimony .carousel-inner::before {
        width: 100vw !important;
        height: 75vw;
        left: 0;
        top: 0;
    }
    .tes-container {
        width: 100vw;
        height: 75vw;
        left: 0;
        
    }

    .tes2 {
        position: absolute;
        width: 90vw;
        height: 65vw;
        top: 5vw;
        left: 4vw;
        right: unset;
        background-color: #FFF1F4;
        border-radius: 10px;
        padding: 9vw 12vw 0 8vw;
        display: flex;
        flex-direction: column;
    
    }

    

    .tes-child1>h5 {
        font-size: 3vw;
    }

    .tes-child1>p {
        font-size: 2.2vw;
    }

    .tes-child2 {
        right: 2vw;
        width: 100%;
        padding-left: 10vw;
    }

    .tes-child2>img {
        width: 30vw;
    }

    .tes-dt {
        width: 18vw;
    }

    .tes-dt h5 {
        font-size: 2.5vw;
        margin-bottom: 0;
    }

    .tes-dt img {
        width: 15vw;
    }

    div.testimony div.carousel.slide a.carousel-control-prev,
    div.testimony div.carousel.slide a.carousel-control-next{
        display: block;
        bottom: -10vw;
    }
}