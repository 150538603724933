.guide {
    text-align: center;
    width: 50vw;
    margin: 8vw auto 5vw;
}

.guide-hd {
    font-family: LatoBold;
    font-size: 2.22vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
}

.guide-bd {
    font-family: SofiaProRegular;
    font-size: 1.33vw;
    color: #745F64;
    line-height: 140%;
    letter-spacing: 0;
}

.res-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2.22vw;
    margin-top: 3.7vw;
}

.res-group1,
.res-group2 {
    height: 64.5vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 2.22vw;
}

.res1,
.res2,
.res3,
.res4 {
    height: 31.2vw;
    width: 31.2vw;
    padding: 1.85vw 1.11vw 0 2.22vw;
    border-radius: 15px;
    position: relative;
    transition: all 0.5s ease-in-out
}

.res1>img,
.res2>img,
.res3>img,
.res4>img {
    width: 15.5vw;
    display: block;
    position: absolute;
    bottom: 0;
    right: 15%;
    transition: all 0.45s ease-in-out;
    animation: check 0.45s ease-in-out 1;
}

.res h5 {
    font-family: LatoBold;
    font-size: 1.8vw;
    color: #FFF;
    line-height: 140%;
    letter-spacing: 0;
}

.res p {
    font-family: SofiaProRegular;
    font-size: 1.18vw;
    color: #FFF;
    line-height: 140%;
    letter-spacing: 0;
}

.res1 {
    background-color: #FC6F92;
}

.res2 {
    background-color: #FDAAB9;
}

.res3 {
    background-color: #745464;
}

.res4 {
    background-color: #FB9CD0;
}

.res1:hover,
.res2:hover,
.res3:hover,
.res4:hover {
    height: 59vw;
    transition: all 0.45s ease-in-out
}

.res1:hover img,
.res2:hover img,
.res3:hover img,
.res4:hover img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform-origin: bottom;
    -webkit-transform-origin: bottom;
    transition: all 0.45s ease-in-out;
}

.res1:hover~.res2>img {
    display: none;
    transition: all 0.45s ease-in-out
}

.res1:has(~ .res2:hover)>img {
    display: none;
    transition: all 0.45s ease-in-out
}

.res3:hover~.res4>img {
    display: none;
    transition: all 0.45s ease-in-out
}

.res3:has(~ .res4:hover)>img {
    display: none;
    transition: all 0.45s ease-in-out
}


@keyframes check {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@media(max-width: 780px) {

    .guide {
        width: 100%;
        padding: 4.1vw 5.1vw;
    }

    .guide-hd {
        font-size: 3vw;
    }

    .guide-bd {
        font-size: 2.5vw;
    }

    .res-container {
        gap: 3vw;
    }

    .res-group1,
    .res-group2 {
        height: calc(40vw + 40vw + 3vw);
        gap: 3vw;
    }

    .res1,
    .res2,
    .res3,
    .res4 {
        width: 40vw;
        height: 40vw;
        padding: 2.5vw;
    }

    .res h5 {
        font-size: 2.2vw;
    }

    .res p {
        font-size: 2vw;
    }

    .res1>img,
    .res2>img,
    .res3>img,
    .res4>img {
        width: 14.5vw;
    }

    .res1:hover,
    .res2:hover,
    .res3:hover,
    .res4:hover {
        height: 60vw;
        transition: all 0.45s ease-in-out
    }

    .res1:hover img,
    .res2:hover img,
    .res3:hover img,
    .res4:hover img {
        transform: scale(1.25);
        -webkit-transform: scale(1.25);
        transform-origin: bottom;
        -webkit-transform-origin: bottom;
        transition: all 0.45s ease-in-out;
    }
}