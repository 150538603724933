.premature {
    padding: 7vw 7.41vw;
}

.premature-container {
    display: flex;
    gap: 3.7vw;
    align-items: center;

}

.premature h5 {
    color: #510115;
    font-size: 2.22vw;
    font-family: LatoBold;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0;
}

.premature p {
    color: #745464;
    font-size: 1.33vw;
    font-family: SofiaProRegular;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0;
}

.incubator {
    position: relative;
    width: 40.25vw;
}

.incubator::before {
    position: absolute;
    content: '';
    right: -1.11vw;
    top: -1.11vw;
    border-radius: 0.75vw;
    height: 26.24vw;
    width: 16.31vw;
    background-color: #FC6F92;
    z-index: 0 !important;

}
.incubator::after {
    position: absolute;
    content: '';
    left: -2.22vw;
    bottom: -1.11vw;
    border-radius: 50%;
    height: 4.67vw;
    width: 4.67vw;
    background-color: #FB9CD0;
    z-index: 0 !important;

}

.incubator * {
    position: relative;
    z-index: 100;
}

@media(max-width: 780px) {
    .premature {
        padding: 10vw 3.5vw;
    }

    .premature-container {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .premature h5 {
        font-size: 3vw;
        line-height: 120%;
    }

    .premature p {
        font-size: 2.5vw;
        line-height: 120%;
    }

    .incubator {
        width: 69vw;
        margin-left: 2vw;
        margin-top: 5vw;
    }

    .incubator img {
        width: 100%;
    }

    .incubator::before {
        width: 23vw;
        height: 45vw;
        top: -1.5vw;
        right: -1.5vw;
    }

    .incubator::after {
        width: 7.9vw;
        height: 7.9vw;
        bottom: -2vw;
        left: -3vw;
    }
}