.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3.7vw 5.93vw 1.5vw 9.34vw;

}

nav {
    margin-left: 4.45vw;
}

nav ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.59vw;
    gap: 2.97vw;
    list-style: none;
    font-family: SofiaProRegular;
}

nav a {
    text-decoration: none;
    color: #745464;
    font-size: 1.33vw;
    line-height: 140%;
    letter-spacing: 0;
}

.active {
    text-decoration: underline;
}

.big-btn {
    font-family: LatoBold;
    font-size: 1.5vw;
    line-height: 140%;
    letter-spacing: 0;
}

.dis-pic {
    width: 3.7vw;
    height: 3.7vw;
    border-radius: 50%;
}

.avatar {
    background-color: #FC6F92;
    color: #fff;
    font-family: LatoBold;
    font-weight: 700;
    font-size: 2.22vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.log-out {
    font-family: SofiaProRegular;
    font-size: 1.78vw;
    font-weight: 600;
}

.log-out:hover {
    color: #745464;
}

.logged {
    display: flex;
    align-items: center;
    gap: 1.5vw;
}

.logged p {
    cursor: pointer;
}


@media(max-width: 780px) {
    .header {
        padding: 16px 20px;
    }
    
    nav {
        margin: 0;
    }

    .menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        background-color: #fff;
        padding: 3.8vw 5.89vw 5.13vw 4.62vw;
    }
    
    .menu-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .menu-bd ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 12vw;
    }
    
    .menu-bd li,
    nav a {
        font-family: LatoBold;
        color: #510115;
        font-size: 3vw;
        font-style: normal;
        line-height: 140%;
        margin-bottom: 10.25vw;
    }
    
    .menu-bd button {
        padding: 2vw 5vw;
        background-color: #FFD700;
        border-radius: 3px;
        border: none;
        color: #510115;
        font-family: LatoBold;
        font-size: 2vw;
        font-style: normal;
        line-height: normal;
    }
}