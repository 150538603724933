.ready h5 {
    font-family: LatoBold;
    font-size: 3.33vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
}

.ready-hd {
    width: 55.6vw;
    text-align: center;
    margin: 1.5vw auto;
}

.journey {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.74vw;
    padding: 1.5vw 5.93vw 1.5vw 8.89vw;
}

.journey-res,
.journey-bump1,
.journey-bump2,
.journey-bf {
    height: 31.87vw;
    width: 20vw;
    position: relative;
}

.journey img {
    width: 100%;
}

.journey-res>img {
    position: absolute;
    top: 0px;
}

.journey-bump1>img {
    position: absolute;
    bottom: 0px;
}

.journey-bump2>img {
    position: absolute;
    top: 2.22vw;
}

.journey-bf>img {
    position: absolute;
    right: 0px;
}

@media(max-width: 780px) {
    .ready h5 {
        font-size: 3.2vw;
    }

    .ready-hd {
        width: 60vw;
    }

    .journey {
        padding: 3.5vw;
        gap: 1vw;
    }

    .journey-res,
    .journey-bump1,
    .journey-bump2,
    .journey-bf {
        height: 37vw;
        width: 22vw;
    }

    .journey-bump2>img {
        top: 3.5vw;
    }
}