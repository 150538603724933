.footer {
    height: 100vh;
    background-color: #EFCDCB;
}

.foot1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px #F5F5F5 solid;
    padding: 30px 150px 30px 100px;
}

.foot1>div {
   display: flex;
   gap: 20px;
}

.foot2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 0.2px #F5F5F5 solid;
    padding: 60px 100px 10px 100px;
}

.foot2 h5 {
    font-family: LatoSemibold;
    font-size: 24;
    color: #510115;
    line-height: 120%;
    letter-spacing: 0;
}

.foot2 p,
.foot3 p {
    font-family: SofiaProRegular;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
}

.foot2 a {
    color: #745464;
    text-decoration: none;
}

.search-bar {
    position: relative;
}

.search-bar>input {
    width: 100%;
    padding: 10px 40px 10px 20px;
    border: none;
    font-family: SofiaProRegular;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
    border-radius: 5px;
}

.search-bar>input:focus {
    border: none;
    outline: none;
}

.search-bar>img {
    position: absolute;
    top: 10px;
    right: 10px;
}

.foot3 {
    margin-top: 50px;
    text-align: center;
}

@media(max-width: 780px) {
    .footer{
        min-height: 100vh;
        height: fit-content;
        padding-bottom: 20px;
    }
    .foot1 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3.5vw;
        padding: 10vw 5vw 3.5vw;
    }
    .foot2 {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5vh;
        padding: 3.2vw 5vw;
    }

    .foot2 h5 {
        font-size: 3vw;
    }

    .foot2 p,
    .foot3 p {
        font-size: 2.5vw;
    }

    .search-bar>input {
        font-size: 2.5vw;
        border-radius: 8px;
        width: 70vw;
    }

    .search-bar>img {
        width: 3vw;
        top: 3vw;
        right: 2.5vw;
    }
}