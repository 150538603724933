.stages {
    padding: 2.97vw 5.93vw 2.97vw 8.89vw;
}

.stages>h5 {
    font-family: LatoBold;
    font-size: 2.22vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
}

.p-stage {
    display: grid;
    grid-template-columns: auto;
    gap: 5vw;
    margin-top: 1.5vw;
    align-items: center;
}

.p-stage>div {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 2.97vw;
    align-items: center;
}
.p-stage img {
    width: 39.3vw;
}

.p-stage h5 {
    font-family: LatoSemibold;
    font-size: 1.78vw;
    line-height: 140%;
    letter-spacing: 0;
    color: #510115;
    margin-top: 1.5vw;
}

.p-stage p {
    font-family: SofiaProRegular;
    font-size: 1.19vw;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
}

@media(max-width: 780px) {
    .stages {
        padding: 15vw 5vw 10vw 3.5vw;
    }

    .stages>h5 {
        font-size: 3vw;
    }

    .p-stage {
        gap: 8vw;
    }

    .p-stage>div:nth-last-of-type(2) {
        display: flex;
        flex-direction: column;
        gap: 5vw;
        align-items: center;
    }

    .p-stage>div:first-of-type,
    .p-stage >div:last-of-type {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: 5vw;
    }

    .p-stage img {
        width: 60vw;
    }

    .p-stage h5 {
        font-size: 2.8vw;
    }

    .p-stage p {
        font-size: 2.5vw;
    }
}