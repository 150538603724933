.newsletter {
    margin: 2.22vw 8.9vw 5vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    border-radius: 5px;
    background-color: #F6E5FA;
}

.newsletter>img {
    width: 450px;
}

.subscribe {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 60px;

}

.subscribe>p {
    font-family: LatoSemibold;
    font-size: 14px;
    color: #745464;
    line-height: 120%;
    letter-spacing: 0;
    margin-bottom: 10px;
}

.subscribe>h5 {
    font-family: LatoSemibold;
    font-size: 38px;
    color: #510115;
    line-height: 120%;
    letter-spacing: 0;
}



.btn-policy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    margin-top: 40px;
}

.btn-policy>p {
    font-family: SofiaProLight;
    font-size: 14px;
    color: #745464;
    line-height: 120%;
    letter-spacing: 0;
}

.btn-policy>p>a {
    color: #510115;
}

@media(max-width: 780px) {
    .newsletter {
        margin: 30px 5vw;
    }

    .newsletter img {
        width: 35vw;
    }

    .subscribe>p {
        font-size: 1.5vw;
        margin-bottom: 0;
    }

    .btn-policy {
        margin-top: 0;
        gap: 1vw;
    }

    .subscribe>h5 {
        font-size: 2.8vw;
    }

    .btn-policy>p {
        font-size: 1.8vw;
    }

    .btn-policy>.subscribe-btn {
        padding: 1.3vw 3.4vw !important;
        font-size: 2vw !important;
        border-radius: 3px !important;
        font-weight: unset;
    }
}