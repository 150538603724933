.map-hd {
    text-align: center;
    margin-top: 12vw;
}

.map-hd h5 {
    font-family: LatoBold;
    font-size: 2.97vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
    margin-bottom: 1.5vw;
}

.map-hd p {
    font-family: SofiaProRegular;
    font-size: 1.19vw;
    line-height: 140%;
    letter-spacing: 0px;
    color: #745464;
    margin-top: 2.22vw;
}

.map-hd>button {
    background-color: #FFD700;
    padding: 1.11vw 4.74vw;
    border: none;
    border-radius: 0.74vw;
    color: #510115;
    font-family: LatoBold;
    font-size: 1.5vw;
    line-height: 140%;
    letter-spacing: 0;
}

.map-hd>button:hover {
    border: 1px #FFD700 solid;
    color: #510115;
    background-color: #fff;
}

.destination {
    position: relative;
    margin: 3vw auto;
    width: 35vw;
}

.destination input {
    width: 100%;
    background-color: #FFF1F4;
    font-family: SofiaProRegular;
    font-size: 1.19vw;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
    border-radius: 0.74vw;
    border: 1px solid #510115;
    padding: 1vw;
}

.destination input:focus {
    outline: #510115;
}

.destination img {
    position: absolute;
    top: 1vw;
    right: 1vw;
}

.placeholder-map {
    margin: 12vw 0;
    position: relative;
    width: 100vw !important;
    height: 100vh !important;
}

.placeholder-map img {
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
    object-fit: cover;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media(max-width: 780px) {
    .map-hd h5 {
        font-size: 3vw;
    }

    .map-hd p {
        font-size: 2.3vw;
    }

    .map-hd>button {
        font-size: 2.6vw;
        background-color: #FFD700;
        border: none;
        padding: 1.6vw 3.5vw;
        border-radius: 4px;
    }

    .destination {
        width: 80vw;
        margin: 10vw 10vw 0;
    }

    .destination input {
        font-size: 2.3vw;
        border-radius: 8px;
        padding: 2vw 4vw;
    }

    .destination img {
        width: 3vw;
        top: 2.5vw;
        right: 2.5vw;
    }

    .placeholder-map img {
        height: 73vw;
    }
}