.nutrition {
    padding: 4.45vw 5.93vw 2.97vw 8.89vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 2.97vw;
}

.p-dish h5 {
    font-family: LatoBold;
    font-size: 2.22vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
    text-align: center;
}

.dish {
    position: relative;
    width: 40vw;
    height: 37.06vw;
    margin-top: 2.22vw;

}
.dish img {
    position: absolute;
}

#dish1 {
    top: 0;
    left: 10%;
    z-index: 50;
}

#dish2 {
    top: 3%;
    right: 25%;
}

#dish3 {
    left: 0;
    top: 32%;
}

#dish4 {
    top: 22%;
    right: 27%;
}

#dish7 {
    bottom: 3%;
    left: 0;
}

#dish5 {
    bottom: 0;
    left: 20%;
    z-index: 50;
}

#dish6 {
    right: 17%;
    bottom: 10%;
}

.p-nutrition p {
    font-family: SofiaProRegular;
    font-size: 16px;
    color: #510115;
    line-height: 120%;
    letter-spacing: 0;
    margin-bottom: 0;
}

.p-nutrition li {
    font-family: SofiaProRegular;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
    padding-top: 25px;
}

.p-nutrition li::marker {
    color: #FC6F92;
    font-size: 2.22vw;
}
@media(max-width: 780px) {
    .nutrition {
        padding: 15vw 2vw 10vw 3.5vw;
        gap: 0;
    }

    .p-dish h5 {
        font-size: 3vw;
    }

    .dish {
        height: 38vw;
        width: 38vw;
    }

    #dish1,
    #dish4,
    #dish3,
    #dish6,
    #dish5 {
        width: 15vw;
    }

    #dish2,
    #dish7 {
        width: 3.7vw;
    }

    #dish4 {
        right: 22%;
    }

    #dish6 {
        right: 12%;
    }

    .p-nutrition {
        padding: 0;
    }
    .p-nutrition p {
        display: none;
    }

    .p-nutrition ul {
        list-style-position: outside !important;
    }

    .p-nutrition li {
        font-size: 2.2vw;
        padding-top: 1vw;
    }

    .p-nutrition li::marker {
        font-size: 1.5vw;
    }

}