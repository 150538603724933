.options {
    padding: 5.19vw 7.41vw;
    background-color: rgba(246, 229, 250, 0.50);
    margin: 5vw 0;
}

.options-main {
    display: flex;
    flex-direction: row;
    gap: 2.97vw;
    margin-bottom: 8vw;
}

.options-main1 h5 {
    font-family: LatoBold;
    font-size: 2.22vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
    margin: 1.5vw 0;
}

.options-main2 {
    padding-top: 2.22vw;
    padding-left: 3.7vw;
}

.options-main2>div:first-of-type {
    padding: 4.45vw 2.22vw 1.5vw 4.45vw;
}

.options-main2 div>div {
    padding: 2.97vw;
    padding-bottom: 0.75vw;
}

.options-main2 h5,
.options-alt h5 {
    font-size: 1.78vw;
    font-family: LatoBold;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0;
}

.options-main2>div>h5,
.options-alt h5 {
    color: #510115;
}

.options-main2 p,
.options-alt p {
    font-size: 1.33vw;
    font-family: SofiaProRegular;
    font-style: normal;
    line-height: 140%;
}

.options-main2>div>p,
.options-alt p {
    color: #745464;
}

.options-main2>div>div {
    color: #fff;
    position: absolute;
    top: 2.97vw;
    left: 1.5vw;

}

.options-main2 div:last-of-type {
    position: relative;
    width: 29.65vw;
}

.options-main2 div:last-of-type>img {
    position: absolute;
    width: 100%;
}

.options-alt {
    display: flex;
    justify-content: space-between;
    margin-top: 4.45vw;
    gap: 11.12vw;
}

@media(max-width: 780px) {
    .options {
        padding: 10vw 3.5vw;
        margin: 10vw 0;
    }

    .options>h5 {
        font-family: LatoBold;
        font-size: 3vw;
        color: #510115;
        line-height: 120%;
        letter-spacing: 0;
        margin-bottom: 6vw;
    }

    .options-main {
        gap: 5vw;
        align-items: center;
    }


    .options-main1>img {
        width: 47vw;
    }


    .options-main2>div:first-of-type {
        width: fit-content;
    }
    .options-main2,
    .options-main2>div:first-of-type {
        padding: 0;
    }

    .options-main2 h5,
    .options-alt h5 {
        font-size: 2.8vw;
        line-height: 120%;
    }

    .options-main2 p,
    .options-alt p {
        font-size: 2.3vw;
        line-height: 120%;
    }

    .options-alt {
        display: block;
        margin-top: 3vw;
    }

    .options-alt>div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 12vw;
    }

    .options-alt>div:first-of-type {
        margin-bottom: 5vw;
    }

    .options-alt>div:first-of-type>div:last-of-type {
        position: relative;
        width: 43vw;
    }

    .options-alt>div:first-of-type>div:last-of-type>img {
        width: 43vw;
    }

    .options-alt>div:first-of-type>div:last-of-type>div {
        position: absolute;
        top: 5vw;
        padding: 0 8vw;
    }
    
    .options-alt>div:first-of-type>div:last-of-type>div p,
    .options-alt>div:first-of-type>div:last-of-type>div h5 {
        color: #fff !important;
    }
}