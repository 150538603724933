.labour {
    padding: 5.19vw 7.41vw;
}

.labour>h5 {
    color: #510115;
    font-size: 2.22vw;
    font-family: LatoBold;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0;
}

.labour-stages {
    display: flex;
    flex-direction: column;
    gap: 2.97vw;
    margin-top: 1.5vw;
}

.labour-stages h5 {
    color: #510115;
    font-size: 1.78vw;
    font-family: LatoBold;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0;
}

.labour-stages1,
.labour-stages2 {
    display: flex;
    gap: 2.97vw;
}

.labour-stages p,
.labour-stages1 li {
    color: #745464;
    font-size: 1.33vw;
    font-family: SofiaProRegular;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0;
}

.labour-stages2 li::marker {
    color: #510115;
}

.labour-stages1 div {
    margin-top: 4.45vw;
}

@media(max-width: 780px) {
    .labour {
        padding: 15vw 3.5vw;
    }

    .labour>h5 {
        font-size: 3vw;
    }

    .labour-stages {
        gap: 5vw;
    }

    .labour-stages1,
    .labour-stages2 {
        gap: 3vw;
    }

    .labour-stages1 ul {
        display: flex;
        gap: 4.5vw;
    }

    .labour-stages1 div {
        margin-top: 0;
    }

    .labour-stages2{
        flex-direction: column;
        
    }

    .labour-stages h5 {
        font-size: 2.8vw;
    }

    .labour-stages p {
        font-size: 2.3vw;
    }

    .labour-stages li {
        font-size: 2.45vw;
    }

    .labour-stages1>img {
        width: 41vw;
        height: 37vw;
    }

    .labour-stages li::marker {
        color: #FC6F92;
    }
}