.privacy-hd {
    background-color: #FFF1F4;
    padding: 70px 210px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.privacy-hd h5 {
    color: #510115;
    font-size: 45px;
    font-family: LatoBold;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.privacy-bd {
    padding: 70px 210px;
}

.privacy-bd p,
.privacy-bd li {
    font-family: SofiaProRegular;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
}

.privacy-bd div:first-of-type {
    margin-bottom: 50px;
}

.privacy-bd h5 {
    color: #510115;
    font-size: 30px;
    font-family: LatoBold;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-top: 40px;
}

.privacy-bd li {
    margin-bottom: 10px;
}

.privacy-bd li::marker {
    color: #FC6F92;
}

.p-diff {
    color: #510115;
}

.p-diff>a {
    color: #510115;
    text-decoration: none;
}


@media(max-width: 780px) {
    .privacy-hd,
    .privacy-bd {
        padding: 9.2vw 11.3vw;
    }

    .privacy-hd h5 {
        font-size: 3.1vw;
    }

    .privacy-hd>img {
        width: 29vw;
    }

    .privacy-bd p,
    .privacy-bd li {
        font-size: 2.5vw;
    }

    .privacy-bd div:first-of-type {
        margin-bottom: 11.5vw;
    }

    .privacy-bd h5 {
        font-size: 3vw;
    }

    .privacy-bd li {
        margin-bottom: 3.5vw;
    }
}