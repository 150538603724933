.why {
    margin: 12vw 7.41vw 1.5vw 9.34vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F6E5FA;
    border-radius: 0.74vw;
}


.why-gyn {
    padding: 0 2.22vw 0 2.59vw;
}

.why-gyn h5 {
    font-family: LatoBold;
    font-size: 2.22vw;
    line-height: 140%;
    letter-spacing: 0px;
    color: #510115;
}

.why-gyn p {
    font-family: SofiaProRegular;
    font-size: 1.33vw;
    line-height: 140%;
    letter-spacing: 0px;
    color: #745464;
    margin-top: 2.22vw;
}

.why img {
    width: 40.77vw;
}

@media(max-width: 780px) {
    .why {
        margin: 10vw 4vw 10vw 3.5vw;
        border-radius: 3px;
        height: 40vw;
    }

    .why-gyn {
        padding: 3.5vw 2.5vw 4vw;
    }

    .why-gyn h5 {
        font-size: 2.7vw;
        line-height: 120%;
    }

    .why-gyn p {
        font-size: 2.2vw;
        margin-top: 0;
        line-height: 120%;
        margin-bottom: 0;
    }

    .why>img {
        height: 100%;
        width: 40vw;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        object-fit: cover;
    }

}