.prenatal {
    padding: 4.45vw 5.93vw 2.97vw 8.89vw;
}

.prenatal>h5 {
    font-family: LatoBold;
    font-size: 2.22vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 3vw;
}

.p-test-nutr {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 4.45vw;
}

.tests>h5,
.nutrients>h5 {
    font-family: LatoBold;
    font-size: 1.78vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
}

.tests ul {
    display: flex;
    flex-direction: column;
    gap: 1.11vw;
    margin-left: 0;
    padding-left: 0;
    list-style-position: inside;
}

.tests li {
    border: 1px #745464 solid;
    border-radius: 10px;
    padding: 2.22vw 1.85vw 1.5vw 2.97vw;
    text-indent: -1.5vw;
    font-family: SofiaProRegular;
    font-size: 1.19vw;
    color: #745464;
}

.tests li:hover {
    background-color: #FC6F92;
    color: #FBFBFB;
    border: 1px #FC6F92 solid;
}

.nutrients ul {
    background-color: #FFF1F4;
    padding: 4.45vw 1.5vw 1.5vw 3.7vw;
    border-radius: 10px;
}

.nutrients li {
    font-family: SofiaProRegular;
    font-size: 1.19vw;
    color: #745464;
    line-height: 140%;
    letter-spacing: 0;
    margin-bottom: 10px;
}

@media(max-width: 780px) {
    .prenatal {
        padding: 15vw 5vw 10vw 3.5vw;
    }

    .prenatal>h5 {
        font-size: 3vw;
    }

    .p-test-nutr {
        flex-direction: column-reverse;
        gap: 3.6vw;
    }

    .tests ul {
        gap: 5vw;
    }

    .tests>h5,
    .nutrients>h5 {
        font-size: 2.8vw;
    }

    .tests li,
    .nutrients li {
        font-size: 2.5vw;
    }

    .nutrients ul {
        padding: 8vw 3vw 5vw 6.5vw ;
    }

    .tests li {
        padding: 2.5vw 2.4vw 3.4vw 6.5vw;
        text-indent: -3.5vw;
    }
}