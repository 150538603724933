.stories-mums {
    padding: 7vw 7.41vw;
}

.stories-mums>h5 {
    color: #510115;
    font-size: 2.22vw;
    font-family: LatoBold;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0;
    text-align: center;
}

.stories-mums-container {
    display: grid;
    gap: 2.22vw;
    grid-template-columns: auto auto;
}

.stories-mums-container div {
    display: flex;
    gap: 0.75vw;
}

.fa-quote-left {
    color: #FC6F92;
}

.stories-mums p {
    color: #745464;
    font-size: 1.33vw;
    font-family: SofiaProRegular;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0;
    margin-top: 0.75vw;
}

@media(max-width: 780px) {
    .stories-mums {
        padding: 5vw 3.5vw 10vw;
    }
    
    .stories-mums-container {
        display: flex;
        flex-direction: column;
    }

    .stories-mums>h5 {
        font-size: 3.2vw;
        line-height: 120%;
    }

    .stories-mums p {
        font-size: 2.5vw;
        line-height: 120%;
    }
}