.how-p {
    padding: 2.97vw 5.93vw 2.97vw 8.89vw;
    margin-top: 7vw;
}

.how-p>h5 {
    font-family: LatoBold;
    font-size: 2.22vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
}

.how-p-container {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 13.34vw;
    row-gap: 7vw;
    margin-top: 5vw;
}

.how-p-container h5 {
    font-family: LatoSemibold;
    font-size: 1.78vw;
    line-height: 140%;
    letter-spacing: 0;
    color: #510115;
    margin-top: 1.5vw;
}

.how-p-container p {
    font-family: SofiaProRegular;
    font-size: 1.19vw;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
}

.content-card p:not(:first-of-type),
.content-card .expanded-btn {
    display: none;
}

.expanded-card .content-btn {
    display: none;
}

.content-btn,
.expanded-btn {
    border: 1px #FFD700 solid;
    color: #510115;
    font-family: LatoBold;
    font-size: 1.5vw;
    line-height: 140%;
    letter-spacing: 0;
    padding: 1.11vw 2.22vw;
    border-radius: 10px;
    background-color: #fff;
}

.content-btn:hover,
.expanded-btn:hover {
    border: none;
    background-color: #FFD700;
}

@media(max-width: 780px) {
    .how-p {
        padding: 15vw 5vw 10vw 3.5vw;
        margin-top: 14vw;
    }
    
    .how-p>h5 {
        font-size: 3.5vw;
    }
    
    .how-p-container {
        display: flex;
        flex-direction: column;
        margin-top: 8vw;
        row-gap: 14vw;
    }

    .how-p-container img {
        width: 15vw;
    }

    .how-p-container h5 {
        font-size: 2.8vw;
    }

    .how-p-container p {
        font-size: 2.5vw;
    }

    .content-btn,
    .expanded-btn {
        font-size: 2.5vw;
        padding: 2.5vw 3vw;
    }
}