.locate {
    padding: 12vw 11.12vw 1.5vw;
}

.locate>h5 {
    font-family: LatoBold;
    font-size: 2.22vw;
    line-height: 140%;
    letter-spacing: 0px;
    color: #510115;
    width: 44.48vw;
    margin: 0 auto;
    text-align: center;
}


.steps {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 5vw 5.93vw;
    margin-top: 2.97vw;
}

.steps>div {
    width: 33.36vw;
    height: 22.24vw;
}


.step {
    position: relative;
}



.diff1 {
    font-family: LatoBold;
    font-size: 2.97vw;
    line-height: 140%;
    letter-spacing: 0px;
    color: #510115;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    width: 3.7vw;
    top: 2.22vw;
    right: 22.24vw;
}

.diff {
    font-family: LatoBold;
    font-size: 2.97vw;
    line-height: 140%;
    letter-spacing: 0px;
    color: #510115;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    width: 3.7vw;
    top: 2.22vw;
    right: unset;
}
.diff2 {
    font-family: LatoBold;
    font-size: 2.97vw;
    line-height: 140%;
    letter-spacing: 0px;
    color: #510115;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    width: 3.7vw;
    top: 2.22vw;
    left: 2.59vw;
}


.step .fade-bg {
    position: absolute;
    right: 1.11vw;
    top: -1.5vw;
    width: 26.4vw;
}
.step .fade-bg3 {
    position: absolute;
    left: 0;
    top: -1.5vw;
    width: 26.4vw;
}

.step .fade-bg2 {
    width: 18vw;
    height: 18vw;
    position: absolute;
    right: unset;
    top: 0;
}


.step .step-jpg {
    position: absolute;
    right: 0;
    background-color: #FFF1F4;
    padding: 1.5vw 4.45vw;
    max-height: 22.24vw;
}
.step .step-jpg3 {
    position: absolute;
    left: 7.4vw;
    top: 0;
    background-color: #FFF1F4;
    padding: 1.5vw 5.93vw;
    max-height: 22.24vw;
}

.step .step-jpg2 {
    position: absolute;
    right: 0;
    background-color: #FFF1F4;
    padding: 4.45vw 2.97vw;
    max-height: 22.24vw;
}

.step-dt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 1.5vw;
}

.step-dt h5 {
    font-family: LatoBold;
    font-size: 1.78vwx;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
}
.step-dt p {
    font-family: SofiaProRegular;
    font-size: 1.33vw;
    color: #745F64;
    line-height: 140%;
    letter-spacing: 0;
    width: 33.36vw;
}

@media(max-width: 780px) {
    .locate {
        padding: 10vw 5vw 10vw 3.5vw;  
    }

    .locate>h5 {
        font-size: 3vw;
        width: unset;
    }

    .steps {
        display: flex;
        flex-direction: column;
        gap: 15vw;
    }

    .steps>div {
        display: flex;
        justify-content: space-between;
        width: unset;
        height: unset;
        gap: 5vw;
        margin-bottom: 5vw;
    }

    .step {
        width: 35vw;
    }

    .step .step-jpg {
        width: 23vw;
        padding: 2.1vw 4.5vw 1vw 3.5vw;
        max-height: 40vw;
    }

    .step .step-jpg3 {
        width: 24vw;
        padding: 2.1vw 5vw 2vw;
        left: unset;
        right: 0;
        max-height: 40vw;
    }

    .step .step-jpg2 {
        width: 28vw;
        padding: 6vw 2vw 7vw 2.7vw;
        max-height: 40vw;
    }

    .step .fade-bg {
        width: 30vw;
        top: -2vw;
        right: 1vw;
    }

    .step .fade-bg3 {
        width: 30vw;
        top: -2vw;
        left: 2vw;
    }

    .step .fade-bg2 {
        width: 26vw;
        height: unset;
        right: 9.5vw;
        top: -2vw;
    }

    .diff,
    .diff1,
    .diff2 {
        font-size: 3.3vw;
        line-height: 120%;
        color: #FC6F92;
    }

    .diff1 {
        left: 2vw;
        top: 2vw;
    }

    .diff2 {
        top: 2vw;
        left: 4vw;
    }

    .diff {
        top: 3vw;
        left: 2vw;
    }

    .step-dt {
        padding: 0;
        width: 43vw;
    }

    .step-dt h5 {
        font-size: 2.8vw;
    }

    .step-dt p {
        font-size: 2.5vw;
        width: 42vw;
        line-height: 120%;
    }
}