.insights {
    padding: 70px 100px;
}

.insights>h5 {
    font-family: LatoBold;
    font-size: 32px;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
    margin-bottom: 20px;
    text-align: center;
}

.insights p {
    color: #745F64;
    font-size: 18px;
    font-family: SofiaProRegular;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.insights>p {
    width: 767px;
    margin: auto;
    text-align: center;
}

.insights-wrapper {
    display: grid;
    grid-template-columns: 270px 270px 270px 270px;
    gap: 20px;

}

.insights-dt h5 {
    color: #510115;
    font-size: 24px;
    font-family: LatoBold;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.insight-container>img {
    width: 100%;
}

.insights-dt {
    border: 0.5px solid #ada8a8;
    border-top: none;
    padding: 30px 10px 10px 20px;
    height: 660px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.expert {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 0;
}

.expert>img {
    width: 45px;
    height: 45px;
}

.expert h5 {
    color: #510115;
    font-size: 20px;
    font-family: LatoBold;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin: 0;
}

@media(max-width: 780px) {
    .insights {
        padding: 3vw 3.5vw 10vw;
    }

    .insights>h5 {
        font-size: 3vw;
    }

    .insights>p {
        font-size: 2.5vw;
        width: 70vw;
        line-height: 120%;
    }

    .insights-wrapper {
        grid-template-columns: 28vw 28vw 28vw;
        gap: 3vw;
        margin-top: 10vw;
    }

    .insights-wrapper p {
        font-size: 2.2vw;
        line-height: 120%;
    }

    .insights-dt {
        padding: 2.3vw 2vw;
        height: 79vw;
        justify-content: unset;
        position: relative;
    }

    .insights-dt>h5 {
        font-size: 2.5vw;
    }

    .expert {
        position: absolute;
        bottom: 0;
        gap: 2vw;
    }

    .expert h5 {
        font-size: 2.3vw;
    }

    .expert img {
        width: 3.1vw;
        height: 3.1vw;
    }
}