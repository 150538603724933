.hero {
    position: relative;
    width: 100vw;
}
.hero-title {
    font-family: LatoBold !important;
    font-size: 3.7vw;
    width: 37vw;
    margin: 0 !important;
    color: #510115;
    text-align: left;

}

.hero-img {
    left: 0px;
    width: 100%;
    height: 62.5vw;
    object-fit: cover;
}

div.carousel-des.carousel-caption.hero1 {
    top: 11vw !important;
    right: 4.5vw !important;
    left: unset !important;
    
}

div.carousel-des.carousel-caption.hero2 {
    top: 2vw !important;
    right: 10vw !important;
    left: unset !important;
}
div.carousel-des.carousel-caption.hero3 {
    top: 7.4vw !important;
    left: 5.9vw !important;
    right: unset !important;
}

span.carousel-control-prev-icon,
span.carousel-control-next-icon,
div.carousel-indicators,
.carousel-control-prev,
.carousel-control-next {
    display: none;
}


@media(max-width: 780px) {
    .hero-img {
        min-height: 40vh;
        height: auto;
        aspect-ratio: 1/0.6;
    }

    .hero-title {
        font-size: 3vw;
        width: 30vw;
    }

    div.carousel-des.carousel-caption.hero1 {
        top: 8vh !important;
        left: 57vw !important;
    }
    div.carousel-des.carousel-caption.hero2 {
        top: 1vh !important;
        left: 60vw !important;
    }
    div.carousel-des.carousel-caption.hero3 {
        top: 2vh !important;
        left: 7vw !important;
    }
}