.how {
    margin: 12vw 0 5vw;
    text-align: center;
}

.how>h5 {
    font-family: LatoBold;
    font-size: 2.22vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
}

.how-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 8.9vw;
    text-align: center;
    margin-top: 3.7vw;
}

.how-hd {
    font-family: LatoBold;
    font-size: 1.8vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
    margin: 1.5vw 0;
}

.how-bd {
    font-family: SofiaProRegular;
    font-size: 1.33vw;
    color: #745464;
    line-height: 140%;
    letter-spacing: 0;
}

@media(max-width: 780px) {
    .how {
        margin: 20vw 0 10vw;
    }

    .how>h5 {
        font-size: 3vw;
    }

    .how-container {
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 0 5.1vw;
        gap: 8vw;
    }

    .how-container img {
        width: 12vw;
    }

    .how-hd {
        font-size: 2.8vw;
    }

    .how-bd {
        font-size: 2.3vw;
    }

    .how-connect {
        justify-items: center;
        align-self: center;
    }
}