.modal-container {
    display: flex;
}

.modal-img {
    position: relative;
    width: 48vw;
}

.modal-img * {
    position: relative;
}

.modal-img::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(242, 82, 124, 0.40);
    ;
    z-index: 0;
}

.modal-img img {
    width: 100%;
}

.modal-img-dt {
    position: absolute;
    top: 25.2vw;
    left: 2.97vw;
    z-index: 1000;
}

.modal-img-dt h5:first-of-type {
    color: #FFF;
    font-family: LatoBold;
    font-size: 2.1vw;
    font-style: normal;
    margin-bottom: 1.11vw;
    line-height: 140%;
}

.modal-img-dt h5:last-of-type {
    color: #FFF;
    font-family: LatoBold;
    font-size: 2.669vw;
    font-style: normal;
    line-height: 140%;
}

.sign {
    width: 52vw;
    padding: 2.97vw 4.45vw 0 6.3vw;
    background-color: rgba(242, 82, 124, 0.02);
}

.sign-exit {
    position: absolute;
    top: 2.97vw;
    right: 4.45vw;
}

.modal-header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.form-group label {
    color: #510115;
    font-size: 1.5vw;
    font-family: LatoBold;
    font-style: normal;
    line-height: 145%;
    text-align: center;
    margin: 0.75vw 0;
}

.form-group {
    position: relative;
    width: 100%;
}

.form-group img {
    position: absolute;
    top: 5.2vw;
    left: 1.5vw;
}

.sign p {
    font-family: SofiaProRegular;
    font-size: 1.33vw;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
    text-align: center;
}


.agree-container {
    display: flex;
    align-items: center;
    margin: 0.75vw 0 1.5vw;
}
.sign>p>span {
    color: rgba(81, 1, 21, 0.80);
    text-decoration: underline;
    cursor: pointer;
}



input#agree {
    width: 1.5vw;
    height: 1.5vw;
    border: 0;
    outline: 0;
}

#agree:checked {
    accent-color: #F25169;
    opacity: 1;
}

.agree {
    font-family: SofiaProRegular;
    font-size: 1.19vw;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
    margin-left: 0.37vw;
}

.agree a {
    color: #745464;
}

.sign>h5:first-of-type {
    color: #510115;
    font-size: 1.7vw;
    font-family: LatoBold;
    font-style: normal;
    line-height: 140%;
    text-align: center;
    margin-top: 2.97vw;
}

.sign .form-group input {
    width: 100%;
    height: 4.82vw;
    border: none;
    border-radius: 12px;
    margin-bottom: 10px;
    color: rgba(116, 84, 100, 0.60) !important;
    font-family: LatoBold;
    font-size: 1.19vw;
    font-style: normal;
    line-height: 145%;
    padding-left: 4.45vw;
}

.sign .form-group input:focus {
    border: none;
    outline: none;
    background-color: #fff;
}

.sign .form-group input:-webkit-autofill,
.form-group input:-webkit-autofill:hover,
.form-group input:-webkit-autofill:focus,
.form-group input:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
    -webkit-text-fill-color: rgba(116, 84, 100, 0.60) !important;
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
}

@keyframes autofill {
    100% {
        background-color: #fff !important;
        color: inherit;
    }
}

@-webkit-keyframes autofill {
    100% {
        background-color: #fff !important;
        color: inherit;
    }
}

.submit-btn {
    width: 100%;
    height: 4.82vw;
    border: none;
    background-color: #FFD700;
    border-radius: 5px;
    font-family: LatoBold;
    font-size: 1.5vw;
    color: #510115;
    letter-spacing: 0;
    line-height: 140%;
    margin-bottom: 1.5vw;
}

.submit-btn:disabled {
    cursor: not-allowed;
}

.alt {
    border-bottom: 0.5px solid rgba(116, 84, 100, 0.50);
    padding: 2.22vw 0 3.7vw 0;
    margin-bottom: 2.22vw;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.alt>p {
    position: absolute;
    bottom: -2.07vw;
    left: 50%;
    background-color: rgb(254, 251, 251);
    padding: 0 0.75vw;
    transform: translateX(-50%);
    color: rgba(116, 84, 100, 0.50);
    text-align: center;
    font-family: SofiaProRegular;
    font-size: 1.33vw;
    font-style: normal;
    line-height: 140%;
}

.alt>.alt-login:first-of-type {
    margin-right: 0.75vw;
}

.alt-login {
    width: 18.53vw;
    height: 3.7vw;
    border-radius: 5px;
    border: 0.5px solid rgba(116, 84, 100, 0.40);
    background-color: transparent;
    color: #510115;
    text-align: center;
    font-family: LatoBold;
    font-size: 1.5vw;
    font-style: normal;
    line-height: 140%;
}

@media(max-width: 780px) {
    .sign {
        width: 100vw;
        padding: 10.2vw 6.5vw;
    }

    .sign-exit {
        top: 40px;
        right: 30px;
    }

    .sign>h5:first-of-type {
        font-size: 14.5px;
        margin-top: 20px;
    }

    .alt-login {
        width: 145px;
        font-size: 11px;
        height: 32px;
    }

    .alt-login img {
        width: 25px;
        height: 25px;
    }

    .form-group label {
        font-size: 11px;
    }

    .sign .form-group input {
        height: 41px;
        font-size: 2.5vw;
        padding-left: 9vw;

    }

    .sign .form-group img {
        width: 14px;
        height: 14px;
        top: 10vw;
    }

    input#agree {
        width: 11.7px;
        height: 11.7px;
    }

    .agree {
        font-size: 10px;
    }

    .submit-btn {
        height: 41px;
        font-size: 3.5vw;
    }

    .sign p {
        font-size: 10px;
    }
}