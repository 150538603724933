.we {
    padding: 12vw 8.89vw 1.5vw 9.34vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4.45vw;
}

.we-bump {
    position: relative;
    width: 37.21vw;
}

.we-bump::before {
    position: absolute;
    content: '';
    width: 17%;
    height: 9.64vw;
    right: -0.37vw;
    top: -0.37vw;
    border-radius: 0.74vw;
    background-color: #FC6F92;
}
.we-bump * {
    position: relative;
}
.we-bump>img {
    width: 37.21vw;
}

.we-care h5 {
    font-family: LatoBold;
    font-size: 2.22vw;
    line-height: 140%;
    letter-spacing: 0px;
    color: #510115;
}

.we-care p {
    font-family: SofiaProRegular;
    font-size: 1.33vw;
    line-height: 140%;
    letter-spacing: 0px;
    color: #745464;
    margin-top: 2.22vw;
}

@media(max-width: 780px) {
    .we {
        padding: 10vw 5vw 10vw 3.5vw;
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 4vw;
    }

    .we-care h5 {
        font-size: 2.8vw;
    }

    .we-care p {
        font-size: 2.3vw;
        margin-top: 2vw;
    }
    
    .we-bump {
        width: 70vw;
    }
    .we-bump>img {
        width: 70vw;
    }

    .we-bump::before {
        height: 35%;
        top: -1vw;
        right: -1vw;
    }
}
