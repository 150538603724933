.forgot-alt {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.forgot-alt input#agree {
    margin-top: 0 !important;
}

.link-format {
    cursor: pointer;
    margin-bottom: 0;
    align-self: center;
}