.preg-community {
    padding: 40px 100px;
}

.preg-community>div:not(:last-of-type) {
    border-bottom: 0.5px #FC6F92 solid;
}

.preg-community-hd {
    display: flex;
    gap: 20px;
    padding-bottom: 30px;
}

.preg-community-hd h5 {
    color: #510115;
    font-size: 30px;
    font-family: LatoBold;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.com-post {
    padding: 30px 0 15px 0;
}

.com-post h5 {
    color: #510115;
    font-size: 24px;
    font-family: LatoBold;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.author {
    display: flex;
    gap: 20px;
    margin: 20px 0;
}

.com-post p:not(.post-main) {
    font-family: SofiaProRegular;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
}

.post-main {
    font-family: SofiaProRegular;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
    width: 950px;
}

.post-utility {
    display: flex;
    gap: 20px;
}

.post-utility div {
    display: flex;
    gap: 10px;
}

.post-utility img {
    width: 17px;
    height: 17px;
}

.more {
    text-align: center;
    padding-top: 70px;
}

.more button {
    color: #510115;
    font-size: 20px;
    font-family: LatoBold;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    border-radius: 5px !important;
}

.fa-heart {
    color: #FC6F92;
    font-size: 18px;
}

@media(max-width: 780px) {
    .preg-community {
        padding: 3vw 3.5vw;
    }

    .preg-community-hd {
        gap: 4vw;
    }

    .preg-community-hd h5 {
        font-size: 3vw;
    }

    .preg-community-hd>.no-colour-btn {
        border: 1px #FFD700 solid;
        color: #510115;
        background-color: #fff;
    }

    .preg-community-hd>.no-colour-btn:hover {
        background-color: #FFD700;
    }

    .com-post {
        padding: 3.1vw 0 2.9vw;
    }
    .com-post h5 {
        font-size: 2.8vw;
    }

    .author {
        font-size: 2.3vw;
        align-items: center;
    }

    .author p {
        margin-bottom: 0;
    }

    .com-post p:not(.post-main) {
        font-size: 2.3vw;
    }

    .post-main {
        font-size: 2.5vw;
        width: 83vw;
    }

    .post-utility {
        gap: 4vw;
    }

    .more {
        padding-top: 12.3vw;
        margin-bottom: 10vw;
    }

    .more button {
        font-size: 2.5vw;
        border-radius: 3px !important;
        padding: 2.2vw 7.4vw;
    }

}