.welcome {
    padding: 70px 100px;
}

.welcome-container {
    position: relative;
    height: 575px;
}

.welcome-container h5 {
    color: #510115;
    font-size: 45px;
    font-family: LatoBold;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    position: absolute;
    left: 0;
    top: 0;
    width: 525px;
}

.welcome-container p {
    font-family: SofiaProRegular;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0;
    color: #745464;
    position: absolute;
    right: 90px;
    top: 0;
    width: 405px;
}

.welcome-container img:first-of-type {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 650px;
}

.welcome-container img:last-of-type {
    position: absolute;
    bottom: 10px;
    right: 0;
    width: 550px;
    height: auto;
}

.welcome-container::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 60px;
    bottom: 0;
    background-color: rgba(255, 241, 244, 0.8);
}

@media(max-width: 780px) {
    .welcome {
        padding: 3vw 3.5vw;
    }

    .welcome-container {
        height: 51vw;
    }

    .welcome-container h5 {
        font-size: 3.2vw;
        line-height: 120%;
        width: 33vw;
    }

    .welcome-container p {
        font-size: 2.3vw;
        line-height: 120%;
        width: 39vw;
        right: 0;
    }

    .welcome-container img:first-of-type {
        width: 49vw;
        bottom: 0;
    }

    .welcome-container img:last-of-type {
        width: 44vw;
        bottom: 0;
    }

    .welcome-container::after  {
        height: 2.9vw;
    }
}