.story-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3vw;
    padding: 10vw 4.4vw 5.5vw;
}

.story-wrapper {
    padding-top: 0;
    margin-bottom: 3vw;
}
.story-hd {
    font-family: LatoBold;
    font-size: 2.4vw;
    line-height: 140%;
    letter-spacing: 0;
    color: #510115;
    
}

.story {
    font-family: SofiaProRegular;
    font-size: 1.33vw;
    line-height: 140%;
    letter-spacing: 0;
    color: #745F64;
}

.story-img {
    width: 42.62vw;
    border-radius: 10px;
    position: relative;
}

.story-img::before {
    position: absolute;
    content: "";
    background-color: rgba(51, 01, 15, 0.25);
    height: 100%;
    width: 100%;
    border-radius: 10px;

}

.story-img>img {
    border-radius: 10px;
    width: 100%;
}

.stat {
    background-color: #FFF1F4;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.22vw;
    padding: 1.5vw 2.22vw;
}

.stats-hd {
    font-family: LatoBold;
    font-size: 2.22vw;
    color: #510115;
    line-height: 140%;
    letter-spacing: 0;
    text-align: center;
}

.stats-bd {
    color: #745F64;
    font-family: SofiaProRegular;
    font-size: 1.33vw;
}

@media(max-width: 780px) {
    .story-body {
        padding: 15vw 5vw 8vw;
        flex-direction: column;
        gap: 7.7vw;
    }

    .story-hd {
        font-size: 3vw;
    }

    .story {
        font-size: 2.5vw;
    }

    .story-img {
        width: 100%;
    }

    .stat {
        padding: 4.6vw 5vw;
        justify-content: space-between;
        gap: 5vw;
    }

    .stats-hd {
        font-size: 3.2vw;
    }

    .stats-bd {
        font-size: 2vw;
        line-height: 120%;
    }

  
    .cta-mobile {
        padding: 3vw 8vw;
        background-color: #FFD700;
        border-radius: 5px;
        border: none;
        color: #510115;
        font-family: LatoBold;
        font-size: 3.2vw;
        font-style: normal;
        line-height: normal;
    }
}