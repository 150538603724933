.p-journey {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4.45vw;
    margin-top: 2.22vw;
    padding: 1.5vw 7.41vw 1.5vw 9.34vw;
}

.p-journey-img {
    position: relative;
    width: 31.13vw;
}

.p-journey-img::before {
    position: absolute;
    content: '';
    right: -2.22vw;
    top: -2.22vw;
    border-radius: 0.75vw;
    height: 34.1vw;
    width: 32.04vw;
    background-color: #FFF1F4;
    z-index: 0 !important;
}

.p-journey-img * {
    z-index: 100;
    position: relative;
}

.p-journey-bd p:first-of-type {
    font-family: LatoBold;
    font-size: 1.33vw;
    color: #FC6F92;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0;
}

.p-journey-bd h5 {
    color: #510115;
    font-size: 2.97vw;
    font-family: LatoBold;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0;
}

.p-journey-bd p:last-of-type {
    color: #745F64;
    font-size: 1.33vw;
    font-family: SofiaProRegular;
    font-style: normal;
    line-height: 140%;
}

.p-journey-bd>.no-colour-btn {
    margin: 2.22vw 0;
}

.p-journey-bd>img {
    margin-top: 1.5vw;
}

@media(max-width: 780px) {
    .p-journey {
        padding: 2vw 5vw 10vw 3.5vw;
        display: block;
    }

    .p-journey>p:first-of-type {
        font-family: LatoBold;
        font-size: 2.8vw;
        color: #FC6F92;
        font-style: normal;
        line-height: 120%;
        letter-spacing: 0;
    }

    .p-journey>p:last-of-type {
        color: #745F64;
        font-size: 2.2vw;
        font-family: SofiaProRegular;
        font-style: normal;
        line-height: 120%;
        margin-bottom: 10vw;
    }

    .p-journey>h5 {
        color: #510115;
        font-size: 3.1vw;
        font-family: LatoBold;
        font-style: normal;
        line-height: 120%;
        letter-spacing: 0;
    }

    .p-journey>h5,
    .p-journey>p {
        width: 51vw;
    }

    .p-journey-group {
        display: flex;
        gap: 4vw;
        margin-left: 2vw;
    }

    .p-journey-img {
        width: 38vw;
    }

    .p-journey-img::before {
        width: 42vw;
        height: 40vw;
        left: -2vw;
        top: -2vw;
    }

    .p-journey-img img {
        width: 38vw;
    }

    .baby-group {
        position: relative;
        width: 48vw;
    }

    .baby-group img {
        width: 22vw;
        aspect-ratio: 1/0.91;
    }

    .baby-group * {
        position: absolute;
    }

    .baby-group .baby1 {
        top: 2vw;
        left: 1vw;
    }
    .baby-group .baby2 {
        top: 23.5vw;
        left: 0;
    }
    .baby-group .baby3 {
        top: 20vw;
        right: 2vw;
    }
}